<template>
  <div class="uploads QualificationUpload1">
    <el-dialog title="证件上传" top="5vh" custom-class="custom-class2" fullscreen :visible.sync="show" @close="cancel">
      <!-- <el-button type="primary" size="mini">清空列表</el-button> -->
      <div class="main m-top-10">
        <!-- <div class="left">
          <div v-for="(i,index) in listImgs" :key="i.IDX" class="img_view m-bottom-10">
            <img :src="i.Url">
            <div class="close___" @click="del(i.IDX,index)">x</div>
          </div>
        </div> -->
        <div class="mid t_line_s">
          <div class="btns p-tb-10 m-left-20 input_file">
            <!-- <el-button type="primary" size="mini" @click="shibie">自动识别</el-button> -->
            <el-button size="medium" @click="$refs.cropper.changeScale(2)">放大</el-button>
            <el-button size="medium" @click="$refs.cropper.changeScale(-2)">缩小</el-button>
            <el-button size="medium" @click="right">顺旋转</el-button>
            <el-button size="medium" @click="left">逆旋转</el-button>
            <el-button type="primary" size="medium" @click="upLoadFile">上传</el-button>
            <!-- <input ref="filElem" type="file" class="upload-file" @change="getFile"> -->
            <input id="file" ref="filElem" type="file" name="file" multiple="multiple" @change="handleFileChange">
          </div>
          <div class="cropper-content">
            <div class="cropper" style="text-align:center">
              <vueCropper
                ref="cropper"
                :img="option.img"
                :output-size="option.size"
                :output-type="option.outputType"
                :info="true"
                :full="option.full"
                :can-move="option.canMove"
                :can-move-box="option.canMoveBox"
                :original="option.original"
                :auto-crop="option.autoCrop"
                :fixed="option.fixed"
                :fixed-number="option.fixedNumber"
                :center-box="option.centerBox"
                :info-true="option.infoTrue"
                :fixed-box="option.fixedBox"
              />
            </div>
          </div>
        </div>
        <div class="right t_line_s">
          <div class="gaodu" />
          <div class="p-lr-20 p-top-20">
            <div class="item flex">
              <div style="width:110px; text-align: left; padding: 5px; background-color: #fafafa;"><span style="color:red;margin-right:5px">*</span>证件名称</div>
              <div style="flex:1">
                <el-select v-model="value" style="width:100%" size="small" placeholder="请选择" @change="isChange">
                  <el-option
                    v-for="item1 in types"
                    :key="item1.IDX"
                    :label="item1.Name"
                    :value="item1.Name"
                  />
                </el-select>
              </div>
            </div>
            <div v-if="item.Sort === 1 || item.Sort === 2" class="item flex m-tb-20">
              <div class="name"><span style="color:red;margin-right:5px">*</span>企业机构名称</div>
              <el-input v-model="form_.CompanyName" size="mini" class="rule-input-edit" style="border:none" />
            </div>
            <div v-if="item.Sort !== 4 && item.Sort !== 3" class="item flex flex m-tb-20">
              <div class="name"><span style="color:red;margin-right:5px">*</span>证件编号</div>
              <el-input v-model="form_.CertificateCode" size="mini" class="rule-input-edit" style="border:none" />
            </div>
            <div v-if="item.Sort === 2 " class="item flex m-tb-20">
              <div class="name"><span style="color:red;margin-right:5px">*</span>库房生产地址</div>
              <el-input v-model="form_.Address" size="mini" class="rule-input-edit" style="border:none" />
            </div>
            <div v-if="item.Sort !== 4 " class="item flex  m-tb-20">
              <div style="width:110px; text-align: left; padding: 5px; background-color: #fafafa;"><span style="color:red;margin-right:5px">*</span>证件有效期至</div>
              <div class="flex a-center">
                <!--<div v-if="!form_.IsLongRange">{{ form_.ValidTo ? $minCommon.formatDate(new Date(form_.ValidTo ),'yyyy/MM/dd') : '' }}</div>-->
                <div v-if="!form_.IsLongRange">
                  <el-date-picker
                    v-model="form_.ValidTo"
                    type="date"
                    size="mini"
                    placeholder="选择日期"
                  />
                </div>
                <!-- <el-popover
                placement="bottom"
                trigger="hover"
                :width="50"
                popper-class="test_pop_view"
              >
                <div>
                  <el-date-picker
                    v-model="form.ValidTo"
                    type="date"
                    size="mini"
                    style="width:100%"
                    placeholder="选择日期"
                  />
                </div>
                <i slot="reference" class="el-icon-time m-lr-10" />
              </el-popover> -->
                <el-checkbox v-model="form_.IsLongRange" class="p-lr-5">长期有效</el-checkbox>
              </div>

            </div>
            <div class="item flex" :class="item.Sort === 4 ? 'm-tb-20' :'' ">
              <div style="width:100%">
                <div class="p-tb-5" style="background:#f1f1f1;width:100%;"><i class="p-left-10" />备注信息</div>
                <el-input v-model="form_.Remark" size="mini" type="textarea" class="rule-input-edit" style="border:none" />
              </div>
            </div>
            <div class="caozuo t-right p-tb-20">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" :loading="isSaveIng" @click="save">保存</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'Uploads',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'shouye'
    },
    types: {
      type: Array,
      default: () => []
    },
    firstCampCompanyID: {
      type: [String, Number],
      default: ''
    },
    idx: {
      type: [String, Number],
      default: ''
    },
    form: {
      type: Object,
      default: () => {}
    },
    supplierID: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      formContent: {

      },
      isSaveIng: false,
      listImgs: [],
      value: '',
      // form: {
      //   Remark: '',
      //   Address: '',
      //   CertificateCode: '',
      //   CompanyName: '',
      //   ValidTo: '',
      //   FileExtName: '',
      //   checked: false
      // },
      item: {},
      // 裁剪组件的基础配置option
      option: {
        view_image: '',
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: false, // 是否默认生成截图框
        // autoCropWidth: 300, // 默认生成截图框宽度
        // autoCropHeight: 500, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        // fixedNumber: [7, 5], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      form_: {

      }
    }
  },
  watch: {
    // idx: {
    //   handler(a) {
    //     console.log(a)
    //     if (a) {
    //       this.option.img = `${this.b2bstaticUrl}${this.form.PhotoUrl}`
    //     }
    //   },
    //   deep: true
    // },
    form: {
      handler(a) {
        console.log('aaa', a)
        this.form_ = JSON.parse(JSON.stringify(a))
        this.value = a.CertificateName
        // this.option.img = `${this.b2bstaticUrl}${a.PhotoUrl}`
      },
      deep: true
    }
  },
  mounted() {
    console.log('组件')
    // this.getTypeMsg()
  },
  methods: {
    cancel() {
      this.form.IsLongRange = false
      this.form_.IsLongRange = false
      this.option.img = ''
      this.isSaveIng = false
      this.$emit('cancel', false)
    },
    isChange(e) {
      console.log(e)
      this.types.map(item => {
        if (item.Name === e) {
          this.form.Code = item.Code
          this.form_.Code = item.Code
          this.form.CertificateName = item.Name
          this.form_.CertificateName = item.Name
          this.item.Sort = item.Sort
        }
      })
    },
    left() {
      this.$refs.cropper.rotateLeft()
    },
    right() {
      this.$refs.cropper.rotateRight()
    },
    shibie() {
      return this.$message.info('暂无接口使用')
      // eslint-disable-next-line no-unreachable
      axios({
        // general_basic
        url: '/v1/business_license?access_token=24.cce5cb83fffe72ad3e15188b42997052.2592000.1617352962.282335-23590046',
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          image: this.imagUrl
        },
        transformRequest: [function(data) {
          let ret = ''
          for (const it in data) {
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
          }
          return ret
        }]
      }).then(res => {
        console.log(res)
        this.form.CompanyName = res.data.words_result['单位名称'].words
        this.form.CertificateCode = res.data.words_result['证件编号'].words
      })
    },
    del(e, index) {
      console.log(e)
      // 删除
      this.$api.DeleteFirstCampCompanyCert({
        IDXList: e + ''
      }).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          this.listImgs.splice(index, 1)
        }
      })
    },
    // 上传
    upLoadFile() {
      this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
    },
    handleFileChange(e) {
      const file = e.target.files[0]
      // console.log('afda', file)
      const size = Math.floor(file.size / 1024 / 1024)
      if (size > 5) {
        return this.$message({
          message: '图片大小不能超过5M',
          type: 'warning'
        })
      }
      var reader = new FileReader()
      //   const url = ''
      reader.readAsDataURL(file)
      // console.log(file)
      this.form.FileExtName = '.' + file.type.split('/')[1]
      this.form_.FileExtName = '.' + file.type.split('/')[1]
      reader.onload = (e) => {
        // encodeURIComponent(e.target.result)
        this.imagUrl = e.target.result
        this.option.img = e.target.result
        this.$refs.filElem.value = null
        // this.form.PhotoUrl = e.target.result
        // console.log(this.option.img)
        // console.log('e.target.result', e.target.result)
        // console.log(this.imagUrl)
        // const arr = this.imagUrl.split(',')
        // this.imagUrl = arr[1]
        // console.log(arr)
        // console.log(this.imagUrl)
        // url = this.result.substring(this.result.indexOf(',') + 1)
        // url = 'data:image/png;base64,' + url
        // that.$refs['imgimg'].setAttribute('src','data:image/png;base64,'+url);
      }
    },
    save() {
      console.log(this.form_.PhotoUrl)
      if (!this.option.img) return this.$message.error('请上传证件')
      // !this.form.Code ||
      if (!this.form_.CertificateName) {
        return this.$message.error('请选择证件名称')
      }
      if (this.item.Sort === 1) {
        if (!this.form_.CompanyName) return this.$message.error('企业机构名称')
        if (!this.form_.CertificateCode) return this.$message.error('证件编号')
        if (!this.form_.IsLongRange) {
          if (!this.form_.ValidTo) return this.$message.error('证件有效期至')
        }
      }
      if (this.item.Sort === 2) {
        if (!this.form_.CompanyName) return this.$message.error('企业机构名称')
        if (!this.form_.CertificateCode) return this.$message.error('证件编号')
        if (!this.form_.Address) return this.$message.error('库房生产地址')
        if (!this.form_.IsLongRange) {
          if (!this.form_.ValidTo) return this.$message.error('证件有效期至')
        }
      }
      if (this.item.Sort === 3) {
        if (!this.form_.IsLongRange) {
          if (!this.form_.ValidTo) return this.$message.error('证件有效期至')
        }
        this.form_.CompanyName = ''
      }
      if (this.item.Sort === 4) {
        this.form_.CompanyName = ''
      }
      // 没有id时的操作
      if (!this.idx) {
        this.$emit('save',
          { firstCampCompanyID: this.firstCampCompanyID,
            base64: this.option.img, form: this.form_
          })
        return this.cancel()
      }
      // 如果有id原逻辑不变
      this.isSaveIng = true
      if (this.type === 'keshang') {
        this.$api.SaveCompanyCertificate({
          IDX: this.idx,
          FirstCampCompanyID: this.firstCampCompanyID,
          TypeID: this.form_.Code,
          Base64String: this.option.img,
          ValidTo: this.form_.ValidTo,
          IsLongRange: this.form_.IsLongRange ? 1 : 0,
          CertificateName: this.form_.CertificateName,
          CompanyName: this.form_.CompanyName,
          Url: `${this.b2bstaticUrl}` + this.form_.PhotoUrl,
          Remark: this.form_.Remark,
          Address: this.form_.Address,
          FileExtName: this.form_.FileExtName,
          CertificateCode: this.form_.CertificateCode,
          CompanyID: this.supplierID
        }).then(res => {
          console.log(res)
          if (res.RetCode !== '0') {
            this.isSaveIng = false
            return this.$message.error(res.RetCode + res.Message)
          }
          if (res.RetCode === '0') {
            this.listImgs = []
            this.isSaveIng = false
            this.$message.success('保存成功！')
            this.listImgs.push(res.Message)
            this.cancel()
          }
        })
        return
      }
      console.log('IDX', this.idx)
      this.$api.SaveFirstCampCompanyCertificate({
        IDX: this.idx,
        FirstCampCompanyID: this.firstCampCompanyID,
        TypeID: this.form_.Code,
        Base64String: this.option.img,
        ValidTo: this.form_.ValidTo,
        IsLongRange: this.form_.IsLongRange ? 1 : 0,
        CertificateName: this.form_.CertificateName,
        CompanyName: this.form_.CompanyName,
        Url: `${this.b2bstaticUrl}` + this.form_.PhotoUrl,
        Remark: this.form_.Remark,
        Address: this.form_.Address,
        FileExtName: this.form_.FileExtName,
        CertificateCode: this.form_.CertificateCode
      }).then(res => {
        console.log(res)
        if (res.RetCode !== '0') {
          this.isSaveIng = false
          return this.$message.error(res.RetCode + res.Message)
        }
        if (res.RetCode === '0') {
          this.listImgs = []
          // this.idx = ''
          this.isSaveIng = false
          this.$message.success('保存成功！')
          this.listImgs.push(res.Message)
          // this.$emit('save',
          //   { firstCampCompanyID: this.firstCampCompanyID,
          //     base64: this.option.img, form: this.form_
          //   })
          // return this.cancel()
          this.cancel()
        }
      }).catch((err) => {
        this.isSaveIng = false
        return this.$message.error(err)
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.uploads{

  .custom-class2{
    .main{
      display: flex;
      justify-content: space-between;
       height:calc(100vh - 20vh);
      .left{
        min-width: 200px;
        padding: 5px;
        overflow:auto;
        .img_view{
            width: 200px;
            height: 200px;
            background-color: #fff;
            border:1px solid #ddd;
            position: relative;
            img{
                width: 100%;
                height: 100%;
            }
            .close___{
                position: absolute;
                right: 5px;
                top: 5px;
                z-index: 9999;
            }
        }
      }
      .left::-webkit-scrollbar {
        display: none;
      }
      .mid{
        flex: 1;
        min-width: 800px;
      }
      .right{
        min-width: 300px;
        .item{
            width: 100%;
            border: 1px solid #ddd;
            .name{
                width:110px;
                text-align: left;
                padding: 5px;
                background-color: #fafafa;
            }
        }
        .item >>> .el-input__inner {
       border: none;
  }

      }
    }
    .el-dialog__body{
      padding: 20px;
    }
    .el-dialog__header{
      padding: 0;
      display: none;
    }
  }
}

  .cropper-content {
      min-width: 500px;
    .cropper {
      //  min-width: 500px;
    //    width: 100%;
       height: calc(100vh - 190px);
    }
}
.rule-input-edit ::v-deep {
  .el-input__inner {
       border: none;
  }
}
.el-select .el-select--small ::v-deep{
    .el-input .el-input--small .el-input--suffix{
        .el-input__inner{
            border: none;
        }
    }
}
</style>
