<template>
  <div class="detail">
    <PageHeader title="企业资料详情">
      <el-button v-if="info.Status === 0 || info.Status === 3" class="ModelBttton1-white-24" type="primary" icon="el-icon-edit" size="mini" @click="toedit">修改</el-button>
      <el-button class="ModelBttton1-white-24" type="info" icon="el-icon-arrow-left" size="mini" @click="() => $router.go(-1)">返回</el-button>
    </PageHeader>
    <div class="p-lr-20 animation" style="right:0">
      <el-form style="right:0" :inline="true" label-position="left" :model="formInline" size="mini" class="demo-form-inline">
        <div class="EnterpriseInformationBox">
          <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 申请信息</div>
          <ul>
            <el-form-item label="申请日期：" class="eachitem">
              {{ info.ApplyDateString }}
            </el-form-item>
            <el-form-item label="申请部门：" class="eachitem">
              {{ info.ApplyDepartment }}
            </el-form-item>
            <el-form-item label="申请人：" class="eachitem">
              {{ info.ApplyUser }}
            </el-form-item>
            <el-form-item label="填表人：" class="eachitem">
              {{ info.FillFormUser }}
            </el-form-item>
          </ul>
        </div>
        <div class="EnterpriseInformationBox">
          <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 企业基本信息</div>
          <ul>
            <el-form-item label="企业名称：" class="eachitem">
              {{ info.CompanyName }}
            </el-form-item>
            <el-form-item label="企业简称：" class="eachitem">
              {{ info.CompanyAbbreviation }}
            </el-form-item>
            <el-form-item label="企业编号：" class="eachitem">
              {{ info.CompanyNo }}
            </el-form-item>
            <el-form-item label="企业类型：" class="eachitem">
              {{ CompanyTypeformatter({CompanyType:info.CompanyType}) }}
            </el-form-item>
          </ul>
          <ul>
            <el-form-item label="联系人：" class="eachitem">
              {{ info.ContactName }}
            </el-form-item>
            <el-form-item label="联系电话：" class="eachitem">
              {{ info.ContactTel }}
            </el-form-item>
            <el-form-item label="传真：" class="eachitem">
              {{ info.Fax }}
            </el-form-item>
            <el-form-item label="邮箱：" class="eachitem">
              {{ info.Email }}
            </el-form-item>
          </ul>
          <ul>
            <el-form-item label="企业法人：" class="eachitem">
              {{ info.CorporationName }}
            </el-form-item>
            <el-form-item label="法人电话：" class="eachitem">
              {{ info.CorporationTel }}
            </el-form-item>
            <el-form-item label="社会统一信用代码：" class="eachitem">
              {{ info.CreditCode }}
            </el-form-item>
            <el-form-item label="被授权人：" class="eachitem">
              {{ info.AuthorizedPerson }}
            </el-form-item>
          </ul>
          <ul>
            <el-form-item label="详细地址：">
              {{ info.Province }}{{ info.City }}{{ info.Area }}{{ info.Street }}
            </el-form-item>
          </ul>
        </div>

        <div class="EnterpriseInformationBox">
          <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 财务信息</div>
          <ul>
            <el-form-item label="开户银行：" class="eachitem">
              {{ info.Bank }}
            </el-form-item>
            <el-form-item label="开户支行：" class="eachitem">
              {{ info.BranchBankProvince }} {{ info.BranchBankCity }} {{ info.BranchBankArea }} {{ info.BranchBank }}
            </el-form-item>
            <el-form-item label="付款方式：" class="eachitem">
              {{ info.PayType }}
            </el-form-item>
            <el-form-item label="回款账期：" class="eachitem">
              {{ info.PaymentCollectionDays }}天
            </el-form-item>
          </ul>
          <ul>
            <el-form-item label="开票税率：" class="eachitem">
              {{ getTaxInfo(info.TaxRate) }}<span v-if="getTaxInfo(info.TaxRate)" />
            </el-form-item>
            <el-form-item label="备注：">
              {{ info.Remark }}
            </el-form-item>
          </ul>
        </div>
      </el-form>
    </div>
    <div class="main_flex flex j-between t_line m-top-20">
      <div class="left min-border-right flex a-center">
        <PageHeader :border="false" title="资质文件" class="m-bottom-10" />
        <!-- <el-upload
          class="upload-demo"
          drag
          action="https://jsonplaceholder.typicode.com/posts/"
          multiple
        >
          <i class="el-icon-upload" />
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload> -->
        <div class="left_list">
          <div v-for="i in tableData1" :key="i.IDX" class="img_view m-bottom-10">
            <img :src="`${b2bstaticUrl}${i.PhotoUrl}`" @click="viewPic(i.PhotoUrl)">
            <div class="close cursor" @click="delImgs(i,true)">x</div>
          </div>
        </div>
      </div>
      <div class="right">
        <PageHeader :border="false" title="已上传的文件" class="m-bottom-10" />
        <div class="p-lr-20">
          <el-table
            ref="multipleTable"
            :border="false"
            :data="tableData1"
            max-height="400px"
            tooltip-effect="dark"
            @select-all="selectAll1"
            @select="select1"
          >
            <el-table-column type="selection" width="42" />
            <el-table-column
              prop="TypeString"
              label="证件类型"
              width="120"
            />
            <el-table-column
              prop="CompanyName"
              label="企业名称"
              width="auto"
            />
            <el-table-column
              prop="CertificateCode"
              label="证件编号"
              width="100"
            />
            <el-table-column
              prop="ValidToString"
              label="证件有效期"
              width="100"
            />
            <el-table-column
              prop="Status"
              label="状态"
              width="80"
            />
            <el-table-column
              prop="address"
              label="操作"
              width="50"
            >
              <template slot-scope="scope">
                <el-popover
                  placement="left"
                  trigger="hover"
                  :width="50"
                  popper-class="test_pop_view"
                >
                  <div>
                    <!-- <div class="min-border-bottom m-tb-10  cursor" @click="editImgs(scope.row)">
                      <i class="el-icon-edit p-right-10" />
                      <span>编辑</span>
                    </div> -->
                    <div class="cursor" @click="delImgs(scope.row,true)">
                      <i class="el-icon-delete p-right-10" />
                      <span>删除</span>
                    </div>
                    <div class="cursor" @click="downLoad(scope.row)">
                      <i class="el-icon-download p-right-10" />
                      <span>下载</span>
                    </div>
                  </div>
                  <i slot="reference" class="el-icon-more" />
                </el-popover>
              </template>
            </el-table-column>
            <div slot="empty">
              <!-- <div class="p-top-20">
                <img src="../../assets/logo.png" alt="" width="140" height="140">
              </div> -->
              <p :style="{'marginTop': '10px'}">表格数据为空</p>
            </div>
          </el-table>
        </div>
        <!-- <Foot /> -->
      </div>
    </div>
    <Uploads :supplier-i-d="info.SupplierID" :type="$route.params.TYPE === '1' ? 'keshang' :'shouye' " :info="info1" :first-camp-company-i-d="$route.params.IDX" :idx="IDX" :types="types" :show="dialogTableVisible1" @cancel="cancels" />
    <el-image-viewer v-if="showViewer" :url-list="MaterialFileList" :on-close="showViewerClose" />
  </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue'
import Uploads from '@/components/uploads.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: 'Detail',
  components: {
    PageHeader,
    Uploads,
    ElImageViewer
  },
  data() {
    return {
      showViewer: false,
      MaterialFileList: [],
      info: {},
      info1: {},
      types: [],
      IDX: '',
      tableData1: [],
      dialogTableVisible1: false,
      formInline: {
        user: '',
        region: ''
      },
      ImgsListSecltStr: '',
      ImgsListSeclt: []
    }
  },
  watch: {
    selects(a) {
      this.selectsStr = ''
      if (a.length === 0) return this.selectsStr = ''
      a.map(item => {
        this.selectsStr += item.IDX + ','
      })
      console.log(this.selectsStr)
    },
    ImgsListSeclt(a) {
      this.ImgsListSecltStr = ''
      if (a.length === 0) return this.ImgsListSecltStr = ''
      a.map(item => {
        this.ImgsListSecltStr += item.IDX + ','
      })
      console.log(this.ImgsListSecltStr)
    }
  },
  mounted() {
    console.log(this.$route.params)
    if (this.$route.params.TYPE === '1') {
      this.$api.GetCompanySupplierInfo({
        IDX: this.$route.params.IDX
      }).then(res1 => {
        console.log('获取企业信息', res1)
        this.info = res1.Message
        this.$api.GetCompanySupplierCertList({
          SupplierID: this.info.SupplierID
        }).then(res => {
          console.log(res)
          this.tableData1 = res.Message
        })
      })
      return
    }
    this.$api.GetFirstCampCompanyInfo({
      IDX: this.$route.params.IDX
    }).then(res1 => {
      console.log('获取企业信息', res1)
      this.info = res1.Message
    })
    this.$api.GetFirstCampCompanyCertificateList({
      firstCampCompanyID: this.$route.params.IDX
    }).then(res => {
      console.log(res)
      this.tableData1 = res.Message
    })
  },
  methods: {
    viewPic(url) {
      this.MaterialFileList = []
      this.MaterialFileList.push(`${this.b2bstaticUrl}${url} `)
      console.log(this.MaterialFileList)
      // this.tableData1.forEach((item, index) => {
      //   this.MaterialFileList.push(`${b2bstaticUrl}${item.PhotoUrl} `)
      // })
      this.showViewer = true
    },
    // 关闭预览
    showViewerClose() {
      this.showViewer = false
    },
    selectAll1(e) {
      this.ImgsListSecltStr = ''
      this.ImgsListSeclt = []
      this.ImgsListSeclt = this.ImgsListSeclt.concat(e)
      console.log(this.ImgsListSeclt)
    },
    select1(row) {
      this.ImgsListSecltStr = ''
      this.ImgsListSeclt = []
      this.ImgsListSeclt = this.ImgsListSeclt.concat(row)

      console.log(this.ImgsListSeclt)
    },
    delImgs(row, isAll) {
      console.log(row)
      if (!isAll && this.ImgsListSecltStr === '') return this.$message.error('没有进行任何勾选')
      this.$confirm('确认删除该文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.DeleteFirstCampCompanyCert({
          IDXList: isAll ? row.IDX + '' : this.ImgsListSecltStr
        }).then(res => {
          if (res.RetCode === '0') {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            if (this.$route.params.TYPE === '1') {
              this.$api.GetCompanySupplierCertList({
                SupplierID: this.info.SupplierID
              }).then(res => {
                console.log(res)
                this.tableData1 = res.Message
              })
              return
            }
            this.$api.GetFirstCampCompanyCertificateList({
              firstCampCompanyID: this.$route.params.IDX
            }).then(res => {
              this.tableData1 = res.Message
            })
          }
        })
      })
    },
    uoLoad() {
      this.dialogTableVisible1 = true
      this.IDX = ''
      this.info1 = {}
      this.getTypeMsg()
    },
    getTypeMsg() {
      this.$api.GetCertificateType({
        CertificateType: 1
      }).then(res => {
        console.log(res)
        this.types = res.Message
      })
    },
    editImgs(row) {
      this.IDX = row.IDX + ''
      this.dialogTableVisible1 = true
      this.$api.GetCertificateType({
        CertificateType: 1
      }).then(res => {
        this.types = res.Message
      })
      this.$api.GetFirstCampCertificateInfo({
        IDX: this.IDX
      }).then(res => {
        console.log(res)
        this.info1 = res.Message
      })
    },
    cancels(e) {
      this.dialogTableVisible1 = e
      console.log('this.info', this.info)
      if (this.$route.params.TYPE === '1') {
        console.log('this.$route.params.TYPE', this.info.SupplierID)
        this.$api.GetCompanySupplierCertList({
          SupplierID: this.info.SupplierID
        }).then(res => {
          console.log(res)
          this.tableData1 = res.Message
        })
        return
      }
      this.getInfo(this.$route.params.IDX)
    },
    getInfo(idx) {
      this.$api.GetFirstCampCompanyCertificateList({
        firstCampCompanyID: idx
      }).then(res => {
        console.log(res)
        this.tableData1 = res.Message
      })
    },
    getTaxInfo(_tax) {
      switch (_tax) {
        case 0.17:
          return '17%'
        case 0.16:
          return '16%'
        case 0.13:
          return '13%'
        case 0.11:
          return '11%'
        case 0.1:
          return '10%'
        case 0.06:
          return '6%'
        case 0.03:
          return '3%'
        case 0.01:
          return '1%'
        case 0:
          return '0%'
        default: return null
      }
    },
    toedit() {
      if (this.$route.params.TYPE === '1') {
        this.$router.push({
          name: 'Edit1',
          params: { IDX: this.$route.params.IDX, TYPE: this.$route.params.TYPE }
        })
        return
      }
      console.log('dsaasd地方撒所多多撒大所')
      this.$router.push({
        name: 'Merchants1',
        params: { IDX: this.$route.params.IDX, active: 1, TYPE: this.$route.params.TYPE }
      })
    },
    // delImgs(row, isAll) {
    //   console.log(row)
    //   this.$confirm('确认删除该文件?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.$api.DeleteFirstCampCompanyCert({
    //       IDXList: row.IDX + ''
    //     }).then(res => {
    //       if (res.RetCode === '0') {
    //         this.$message({
    //           type: 'success',
    //           message: '删除成功!'
    //         })
    //         this.getInfo(this.$route.params.IDX)
    //       }
    //     })
    //   })
    // },
    downLoad(row) {
      console.log(row)
      this.$api.GetFirstCampFiles({
        FileURL: this.b2bstaticUrl + row.PhotoUrl
      }).then(res => {
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$minCommon.downloadFile(res.Message, '.' + row.PhotoUrl.split('.')[1])
      })
    },
    CompanyTypeformatter(row, column) {
      return row.CompanyType === 1 ? '客户' : row.CompanyType === 2 ? '供应商' : '客户/供应商'
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/style/product.scss';
.info_c{
 height: auto;
  position: relative;
  overflow: hidden;
  // background-color: red;
  transition: all 2s ease ;
  .more{
    position: absolute;
    right: 50px;
    top: 20px;
    color: #096dcd;
  }
}
  .main_flex{
    // width: 100%;
    min-height: 400px;
    .left{
      width: 280px;
      height: 455px;
      overflow-y: auto;
      flex-direction: column;
      .left_list {
        width: 100%;
        height: 400px;
        overflow: auto;
      }
       .img_view{
            width: 200px;
            height: 200px;
            background-color: #fff;
            border:1px solid #ccc;
            position: relative;
            margin:10px auto 0px auto;
            img{
                width: 100%;
                height: 100%;
            }
            .close{
                position: absolute;
                right: 5px;
                top: 5px;
            }
        }
    }
    .right{
      flex: 1;
      min-width: 400px;
    }
  }
</style>
